
import { defineComponent, reactive } from 'vue';
import Container from '@/components/Container.vue';
import useRoles from '@/modules/useRoles';
import store from '@/store';

interface RoleRecord {
  actions: string[],
  value: string,
  label: string,
}

export default defineComponent({
  components: {
    Container,
  },
  beforeRouteEnter(from, to, next) {
    if (store.state.auth.isAdmin) {
      next();
    } else {
      next({ path: '/login' });
    }
  },
  setup() {
    const { roles } = useRoles();
    const rows: string[] = reactive([]);
    const cols: any = reactive([
      {
        label: 'Доступные действия',
        display: (row: string) => row,
      },
    ]);

    roles
      .fetch()
      .then(() => {
        roles.options.forEach((role: RoleRecord) => role.actions.forEach((action: string) => {
          if (!rows.includes(action)) rows.push(action);
        }));

        roles.options.forEach((item: RoleRecord) => cols.push({
          label: item.label,
          component: (row: string) => ({
            name: 'RolesCheckbox',
            isChecked: item.actions.includes(row),
            disabled: true,
          }),
        }));
      });

    return {
      cols,
      rows,
    };
  },
});
